export const API_ROOT = process.env.REACT_APP_API_ROOT;

export const ROLE = {
    STUDENT: 'STUDENT',
    TEACHER: 'TEACHER',
    PARTNER: 'PARTNER',
    SALE_PARTNER: 'SALE_PARTNER'
}
export const ROLE_LABEL = {
    STUDENT: 'Student',
    TEACHER: 'Teacher',
    PARTNER: 'Partner',
    SALE_PARTNER: 'Sale Partner'
}

export const SAT_TABLE = [
    { readingAndWriting: { lower: 200, upper: 200 }, math: { lower: 200, upper: 200 } },
    { readingAndWriting: { lower: 200, upper: 200 }, math: { lower: 200, upper: 200 } },
    { readingAndWriting: { lower: 200, upper: 200 }, math: { lower: 200, upper: 200 } },
    { readingAndWriting: { lower: 200, upper: 200 }, math: { lower: 200, upper: 200 } },
    { readingAndWriting: { lower: 200, upper: 200 }, math: { lower: 200, upper: 200 } },
    { readingAndWriting: { lower: 200, upper: 200 }, math: { lower: 200, upper: 200 } },
    { readingAndWriting: { lower: 200, upper: 200 }, math: { lower: 200, upper: 200 } },
    { readingAndWriting: { lower: 200, upper: 210 }, math: { lower: 200, upper: 220 } },
    { readingAndWriting: { lower: 200, upper: 220 }, math: { lower: 200, upper: 230 } },
    { readingAndWriting: { lower: 210, upper: 230 }, math: { lower: 220, upper: 250 } },
    { readingAndWriting: { lower: 230, upper: 250 }, math: { lower: 250, upper: 280 } },
    { readingAndWriting: { lower: 240, upper: 260 }, math: { lower: 280, upper: 310 } },
    { readingAndWriting: { lower: 250, upper: 270 }, math: { lower: 290, upper: 320 } },
    { readingAndWriting: { lower: 260, upper: 280 }, math: { lower: 300, upper: 330 } },
    { readingAndWriting: { lower: 280, upper: 300 }, math: { lower: 310, upper: 340 } },
    { readingAndWriting: { lower: 290, upper: 310 }, math: { lower: 320, upper: 350 } },
    { readingAndWriting: { lower: 320, upper: 340 }, math: { lower: 330, upper: 360 } },
    { readingAndWriting: { lower: 340, upper: 360 }, math: { lower: 330, upper: 360 } },
    { readingAndWriting: { lower: 350, upper: 370 }, math: { lower: 340, upper: 370 } },
    { readingAndWriting: { lower: 360, upper: 380 }, math: { lower: 350, upper: 380 } },
    { readingAndWriting: { lower: 370, upper: 390 }, math: { lower: 360, upper: 390 } },
    { readingAndWriting: { lower: 370, upper: 390 }, math: { lower: 370, upper: 400 } },
    { readingAndWriting: { lower: 380, upper: 400 }, math: { lower: 370, upper: 400 } },
    { readingAndWriting: { lower: 390, upper: 410 }, math: { lower: 380, upper: 410 } },
    { readingAndWriting: { lower: 400, upper: 420 }, math: { lower: 390, upper: 420 } },
    { readingAndWriting: { lower: 410, upper: 430 }, math: { lower: 400, upper: 430 } },
    { readingAndWriting: { lower: 420, upper: 440 }, math: { lower: 420, upper: 450 } },
    { readingAndWriting: { lower: 420, upper: 440 }, math: { lower: 430, upper: 460 } },
    { readingAndWriting: { lower: 430, upper: 450 }, math: { lower: 440, upper: 470 } },
    { readingAndWriting: { lower: 440, upper: 460 }, math: { lower: 460, upper: 490 } },
    { readingAndWriting: { lower: 450, upper: 470 }, math: { lower: 470, upper: 500 } },
    { readingAndWriting: { lower: 460, upper: 480 }, math: { lower: 480, upper: 510 } },
    { readingAndWriting: { lower: 460, upper: 480 }, math: { lower: 500, upper: 530 } },
    { readingAndWriting: { lower: 470, upper: 490 }, math: { lower: 510, upper: 540 } },
    { readingAndWriting: { lower: 480, upper: 500 }, math: { lower: 520, upper: 550 } },
    { readingAndWriting: { lower: 490, upper: 510 }, math: { lower: 530, upper: 560 } },
    { readingAndWriting: { lower: 490, upper: 510 }, math: { lower: 550, upper: 580 } },
    { readingAndWriting: { lower: 500, upper: 520 }, math: { lower: 560, upper: 590 } },
    { readingAndWriting: { lower: 510, upper: 530 }, math: { lower: 570, upper: 600 } },
    { readingAndWriting: { lower: 520, upper: 540 }, math: { lower: 580, upper: 610 } },
    { readingAndWriting: { lower: 530, upper: 550 }, math: { lower: 590, upper: 620 } },
    { readingAndWriting: { lower: 540, upper: 560 }, math: { lower: 600, upper: 630 } },
    { readingAndWriting: { lower: 540, upper: 560 }, math: { lower: 620, upper: 650 } },
    { readingAndWriting: { lower: 550, upper: 570 }, math: { lower: 630, upper: 660 } },
    { readingAndWriting: { lower: 560, upper: 580 }, math: { lower: 650, upper: 680 } },
    { readingAndWriting: { lower: 570, upper: 590 }, math: { lower: 670, upper: 700 } },
    { readingAndWriting: { lower: 580, upper: 600 }, math: { lower: 690, upper: 720 } },
    { readingAndWriting: { lower: 590, upper: 610 }, math: { lower: 710, upper: 740 } },
    { readingAndWriting: { lower: 590, upper: 610 }, math: { lower: 730, upper: 760 } },
    { readingAndWriting: { lower: 600, upper: 620 }, math: { lower: 740, upper: 770 } },
    { readingAndWriting: { lower: 610, upper: 630 }, math: { lower: 750, upper: 780 } },
    { readingAndWriting: { lower: 620, upper: 640 }, math: { lower: 760, upper: 790 } },
    { readingAndWriting: { lower: 630, upper: 650 }, math: { lower: 770, upper: 800 } },
    { readingAndWriting: { lower: 630, upper: 650 }, math: { lower: 780, upper: 800 } },
    { readingAndWriting: { lower: 640, upper: 660 }, math: { lower: 790, upper: 800 } },
    { readingAndWriting: { lower: 650, upper: 670 }, math: null },
    { readingAndWriting: { lower: 660, upper: 680 }, math: null },
    { readingAndWriting: { lower: 670, upper: 690 }, math: null },
    { readingAndWriting: { lower: 680, upper: 700 }, math: null },
    { readingAndWriting: { lower: 690, upper: 710 }, math: null },
    { readingAndWriting: { lower: 700, upper: 720 }, math: null },
    { readingAndWriting: { lower: 710, upper: 730 }, math: null },
    { readingAndWriting: { lower: 720, upper: 740 }, math: null },
    { readingAndWriting: { lower: 730, upper: 750 }, math: null },
    { readingAndWriting: { lower: 750, upper: 770 }, math: null },
    { readingAndWriting: { lower: 770, upper: 790 }, math: null },
    { readingAndWriting: { lower: 790, upper: 800 }, math: null }
]

export const DOMAIN = {
    INFORMATION_AND_IDEAS: "Information and Ideas",
    CRAFT_AND_STRUCTURE: "Craft and Structure",
    EXPRESSION_OF_IDEAS: "Expression of Ideas",
    STANDARD_ENGLISH_CONVENTIONS: "Standard English Conventions",
    ALGEBRA: "Algebra",
    ADVANCED_MATH: "Advanced Math",
    PROBLEM_SOLVING: "Problem-Solving and Data Analysis",
    GEOMETRY_AND_TRIGINOMETRY: "Geometry and Trigonometry",
}