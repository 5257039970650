import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchMyProfileAPI } from '../../apis'; // Import your API function here


// Async action to fetch user profile data
export const fetchUserProfile = createAsyncThunk('user/fetchUserProfile', async () => {
    const response = await fetchMyProfileAPI(); // Assuming you have an API to fetch the user profile
    return response;
});

// Define the initial state
const initialState = {
    isAuthorized: localStorage.getItem("accessToken") ? true : false,
    id: null,
    username: '',
    email: '',
    partnerId: null,
    phone: '',
    name: '',
    school: '',
    city: '',
    birthYear: null,
    roles: [],
    status: '',
    isLoading: false,
    isError: false,
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        resetUser: () => {
            return initialState;
        },
        setIsAuthorized: (state, action) => {
            state.isAuthorized = action.payload;
          },
        setUser: (state, action) => {
            state = {...state, ...action.payload};
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchUserProfile.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchUserProfile.fulfilled, (state, action) => {
            const userData = action.payload;
            state.id = userData.id;
            state.username = userData.username;
            state.email = userData.email;
            state.phone = userData.phone;
            state.partnerId = userData.partner?.id;
            state.name = userData.name;
            state.school = userData.school;
            state.city = userData.city;
            state.birthYear = userData.birthYear;
            state.roles = userData.roles;
            state.status = userData.status;
            state.isLoading = false;
            state.isError = false;
        });
        builder.addCase(fetchUserProfile.rejected, (state) => {
            state.isLoading = false;
            state.isError = true;
        });
    },
});

export default userSlice.reducer;
export const { resetUser, setUser, setIsAuthorized } = userSlice.actions;
