// src/components/Layout.js
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './UserLayout.css'
import { Button, Layout, message } from 'antd';
import Sider from 'antd/es/layout/Sider';
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
import Logo from '../Logo/Logo';
import MenuList from '../MenuList/MenuList';
import { Content, Header } from 'antd/es/layout/layout';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTokenAPI } from '../../apis';
import { fetchUserProfile, setIsAuthorized } from '../../redux/slice/userSlice';
const UserLayout = ({ children }) => {
  const location = useLocation();
  const isHideNavbar = ['/mock'].some(route => location.pathname.startsWith(route));
  const isAuthorized = useSelector((state) => state.user.isAuthorized);
  const dispath = useDispatch();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [collapsed, setCollapsed] = useState(true);
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await fetchTokenAPI(username, password);
    if (response?.data.code === 1000) {
      dispath(setIsAuthorized(true))
      message.success('Login success')
      localStorage.setItem('accessToken', response?.data.result.token);
      navigate('/activities')
      dispath(fetchUserProfile())
    }
  }

  if (isHideNavbar) {
    return <div style={{ height: '100vh', width: '100vw', backgroundColor: '# ' }}>{children}</div>;
  }


  return (
    <div>
      <Layout>
        <Sider className='sidebar' collapsed={collapsed}
          collapsible
          trigger={null}
          theme='light'
        >
          <Logo />
          <MenuList />
        </Sider>
        <Layout>
          <Header style={{ padding: 0, backgroundColor: 'white' }}>
            <div className="d-flex py-3 justify-content-between">
              <Button
                type='text'
                className="toggle-sidebar"
                onClick={() => {
                  setCollapsed(!collapsed);
                }}
                icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              />
              {!isAuthorized &&
                <form onSubmit={handleSubmit} className="d-flex gap-2 pb-4 flex-row mx-2">
                  <div class="input-group mb-3">
                    <span class="input-group-text" id="basic-addon1"><i class="fas fa-user-circle"></i></span>
                    <input type="text" value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      className="form-control" placeholder="Username"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                    />
                  </div>
                  <div class="input-group mb-3">
                    <span class="input-group-text" id="basic-addon1"> <i class="fas fa-lock"></i></span>
                    <input type="password"
                      className="form-control"
                      placeholder="Password"
                      aria-label="Password"
                      onChange={(e) => setPassword(e.target.value)}
                      aria-describedby="basic-addon1"
                    />
                  </div>
                  <button
                   type='submit'
                    className="btn btn-dark mb-3"
                  >
                    Login
                  </button>
                </form>

              }
            </div>

          </Header>
          <Content style={{ backgroundColor: '#fff' }}>
            {children}
          </Content>
        </Layout>
      </Layout>


    </div>
  );
};

export default UserLayout;
