import React, { useEffect, useState } from "react";
import { Table, Checkbox, message } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import { fetchPermissionsAPI, fetchRolesAPI, updateRoleAPI } from "../../apis";


const RoleManagement = () => {
    const [permissionsData, setPermissionsData] = useState([]);
    const [rolesData, setRolesData] = useState([]);

    const fetchData = async () => {
        const roleResponse = await fetchRolesAPI();
        setRolesData(roleResponse.result || []);
        const permissionResponse = await fetchPermissionsAPI();
        setPermissionsData(permissionResponse.result || []);
    };

    const handleCheckboxChange = (roleName, permissionName) => {
        setRolesData((prevRolesData) => {
            return prevRolesData.map((role) => {
                if (role.name === roleName) {
                    if (role.permissionArr.includes(permissionName)) {
                        role.permissionArr = role.permissionArr.filter(
                            (permission) => permission !== permissionName
                        );
                    } else {
                        role.permissionArr.push(permissionName);
                    }
                }
                return role;
            });
        });
        console.log(rolesData);
    };
    const handleSave = async (roleName) => {
        const permissions = rolesData.find((role) => role.name === roleName).permissionArr;
        const roleRequest = {
            name: roleName,
            permissions: permissions,
        };
        try {
            const response = await updateRoleAPI(roleName, roleRequest);
            message.success(response.message);
        } catch (error) {
            message.error(error.data.message);
        }

        //console.log(response);
    }

    useEffect(() => {
        fetchData();
    }, []);

    // Define columns for Role Management Table
    const roleColumns = [
        {
            title: "Role",
            dataIndex: "name",
            key: "name",
        },
        ...permissionsData.map((permission) => ({
            title: permission.name,
            key: permission.name,
            render: (_, role) => (
                <Checkbox
                    checked={role.permissionArr.includes(permission.name)}
                    onChange={() => handleCheckboxChange(role.name, permission.name)}
                />
            ),
        })),
        {
            title: "Action",
            key: "action",
            render: (_, role) => (
                <button className="btn btn-sm btn-dark" onClick={() => handleSave(role.name)} >Save</button>
            ),
        },
    ];

    // Define columns for Permission Management Table
    const permissionColumns = [
        {
            title: "Permission",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Description",
            dataIndex: "description",
            key: "description",
        },
    ];

    return (
        <div className="container">
            <div className="d-flex">
                <h1 className="p-0 m-0">Role Management</h1>
                <div className="ms-4">
                    <button className="btn btn-dark" onClick={()=>fetchData()}>
                        <ReloadOutlined
                            style={{ cursor: "pointer" }}
                        />
                    </button>
                </div>

            </div>
            <Table
                dataSource={rolesData}
                columns={roleColumns}
                rowKey="name"
                bordered
                pagination={false}
            />

            <h1>Permission Management</h1>
            <Table
                dataSource={permissionsData}
                columns={permissionColumns}
                rowKey="name"
                bordered
                pagination={false}
            />
        </div>
    );
};

export default RoleManagement;
