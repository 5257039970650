import { Button, Form, Input, message } from 'antd';
import React, { useEffect } from 'react';
import { createStudentAPI, createStudentForPartnerAPI, updateUserAPI } from '../../apis';
import { useSelector } from 'react-redux';
import { ROLE } from '../../utils/constants';

const StudentForm = ({ selectedStudent, isAddMode, onCancel, fetchStudentList }) => {
    const [form] = Form.useForm();
    const roles = useSelector(state => state.user.roles);
    const partnerId = useSelector(state => state.user.partnerId);
    useEffect(() => {
        if (selectedStudent) {
            form.resetFields();
            form.setFieldsValue(selectedStudent);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedStudent]);
    const handleSubmit = async () => {
        await form.validateFields();
        let data = form.getFieldsValue();
        data.roles = [ROLE.STUDENT];
        const createStudent = async () => {
            try {
                if (roles.some(role => role.name === ROLE.TEACHER)) {
                    await createStudentAPI(data);
                    message.success('Student added successfully');
                    fetchStudentList();
                    onCancel();
                } else if (partnerId) {
                    await createStudentForPartnerAPI(partnerId, data)
                        .then(() => {
                            message.success('Student updated successfully');
                            fetchStudentList();
                            onCancel();
                        })
                        .catch((error) => {
                            message.error(error.response.data.message);
                        });
                }
            } catch (error) {
                message.error(error.response.data.message);
            }
        }
        const updateStudent = async () => {
            await updateUserAPI(selectedStudent.id, data)
                .then(() => {
                    message.success('Student updated successfully');
                    fetchStudentList();
                    onCancel();
                })
                .catch((error) => {
                    message.error(error.message);
                });
        }
        if (isAddMode) {
            createStudent();
        } else {
            updateStudent();
        }
     
    }
    return (
        <div>
            <Form layout='vertical' form={form} onFinish={handleSubmit}>
                <Form.Item
                    label="Username"
                    name="username"
                  
                    rules={[
                        { required: true, message: 'Please input your username!' },
                        { min: 4, message: 'Username must be at least 4 characters long' },
                        { pattern: /^[a-z0-9]+$/, message: 'Username can only contain lowercase letters and numbers, no spaces or special characters.' }
                    ]}
                >
                    <Input placeholder='Enter username' disabled={!isAddMode} />
                </Form.Item>
                <Form.Item
                    label="Full Name"
                    name="name"
                    rules={[{ required: true, message: 'Please input your full name!' }]}
                >
                    <Input placeholder='Enter full name' />
                </Form.Item>
                {isAddMode && <>
                    <Form.Item
                        label="Password"
                        name="password"
                        rules={[
                            { required: true, message: 'Please input your password!' },
                            { min: 6, message: 'Password must be at least 6 characters long' },
                        ]}
                    >
                        <Input.Password placeholder='Enter password' />
                    </Form.Item>

                    <Form.Item
                        label="Confirm Password"
                        name="confirmPassword"
                        dependencies={['password']}
                        rules={[
                            { min: 6, message: 'Password must be at least 6 characters long' },
                            { required: true, message: 'Please confirm your password!' },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('password') === value) return Promise.resolve();
                                    return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                },
                            }),
                        ]}
                    >
                        <Input.Password placeholder='Enter confirm password' />
                    </Form.Item>
                </>
                }
                {!isAddMode && <Form.Item label="Student From">
                    <Input disabled={!isAddMode} value={selectedStudent?.partner?.name} />
                </Form.Item>}
                <Form.Item
                    label="Year of Birth"
                    name="birthYear"
                    rules={[
                        { required: false },
                        {
                            pattern: /^\d{4}$/,
                            message: 'Please enter a valid year (YYYY)',
                        },
                        {
                            validator: (_, value) => {
                                const year = parseInt(value);
                                const currentYear = new Date().getFullYear();
                                if (!value || (year >= 1900 && year <= currentYear)) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(`Year must be between 1900 and ${currentYear}`);
                            }
                        }
                    ]}
                >
                    <Input
                        type="number"
                        placeholder="YYYY"
                        min={1900}
                        max={new Date().getFullYear()}
                    />
                </Form.Item>
                <div className='row'>
                    <div className='col-md-6'>
                        <Form.Item
                            label="Email"
                            name="email"
                            rules={[
                                {
                                    type: 'email',
                                    message: 'Please enter a valid email address',
                                },
                            ]}
                        >
                            <Input placeholder="Enter email" />
                        </Form.Item>
                    </div>
                    <div className='col-md-6'>
                        <Form.Item
                            label="Phone"
                            name="phone"
                            rules={[
                                {
                                    pattern: /^(\+?\d{1,3}[- ]?)?\d{9,15}$/,
                                    message: 'Số điện thoại không hợp lệ. Ví dụ: 0912345678, +84912345678, +1234567890',
                                },
                            ]}
                        >
                            <Input placeholder="Enter phone number" />
                        </Form.Item>
                    </div>
                    <div className='col-md-6'>
                        <Form.Item label="School" name="school">
                            <Input placeholder='Enter school' />
                        </Form.Item>
                    </div>
                    <div className='col-md-6'>
                        <Form.Item label="City" name="city">
                            <Input placeholder='Enter city' />
                        </Form.Item>
                    </div>
                </div>



                <div className="d-flex justify-content-end gap-2">
                    <Button type="primary" onClick={handleSubmit}>
                        {isAddMode ? "Add Student" : "Update Student"}
                    </Button>
                </div>
            </Form>


        </div>
    );
};

export default StudentForm;
