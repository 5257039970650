import React, { useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home/Home';
import Mock from './pages/Mock/Mock';
import Login from './pages/Login/Login';
import ProtectedRoutes from './utils/ProtectedRoutes';
import Register from './pages/Register/Register';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UploadMock from './pages/UploadMock/UploadMock';
import AssignAttempt from './pages/AssignAttempt/AssignAttempt';
import ScorePage from './pages/ScorePage/ScorePage';
import { useDispatch } from 'react-redux';
import { fetchUserProfile } from './redux/slice/userSlice';
import AllAttempt from './pages/AllAttempt/AllAttempt';
import UserLayout from './components/Layout/UserLayout'; // Import the new Layout component
import TestCompletion from './pages/TestCompletion/TestCompletion';
import DesmosTest from './components/DesmosComponent/DesmosComponent';
import Highlight from './components/Highlight/Hightlight';
import DevPage from './pages/DevPage/DevPage';
import ActivitiesPage from './pages/Activities/ActivitiesPage';
import StudentManagement from './pages/StudentManagement/StudentManagement';
import PartnerManagement from './pages/PartnerManagement/PartnerManagement';
import PartnerSaleManagement from './pages/PartnerSaleMangement/PartnerSaleManagement';
import RoleManagement from './pages/RoleMangement/RoleManagement';

function App() {
  const dispatch = useDispatch();
  const accessToken = localStorage.getItem('accessToken');



  useEffect(() => {
    if (accessToken) {
      dispatch(fetchUserProfile());
    }
  }, [accessToken, dispatch]);

  return (
    <Router>
      <UserLayout> {/* Use the Layout component */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route element={<ProtectedRoutes />}>
            <Route path="/mock/:attemptId" element={<Mock />} />
            <Route path="/activities" element={<ActivitiesPage />} />
            <Route path="/assign" element={<AssignAttempt />} />
            <Route path="/score" element={<ScorePage />} />
            <Route path="/all-attempt" element={<AllAttempt />} />
            <Route path="/test-completion" element={<TestCompletion />} />
            <Route path="/upload" element={<UploadMock />} />
            <Route path="/register" element={<Register />} />
            {/*  Management Page */}
            <Route path="/student-management" element={<StudentManagement />} />
            <Route path="/partner-management" element={<PartnerManagement />} />
            <Route path="/sale-management" element={<PartnerSaleManagement />} />
            <Route path="/role-management" element={<RoleManagement />} />
          </Route>
          <Route path="/desmos" element={<DesmosTest />} />
          <Route path="/login" element={<Login />} />
          <Route path="/highlight" element={<Highlight />} />
          <Route path="/dev" element={<DevPage />} />
        </Routes>
      </UserLayout>
      <ToastContainer
        position="top-right"
        autoClose={1500}
        hideProgressBar={true}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false} // Disable pause on hover
      />
    </Router>
  );
}

export default App;
